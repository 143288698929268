<template>
  <HxTable v-model:columns="columns" :reload="loadData">
    <template #search>
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="">
          <a-input v-model:value="searchForm.mobile" placeholder="电话号" />
        </a-form-item>
        <a-space>
          <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onSearch"
                    :disabled="loading">
            查询
          </a-button>
          <a-button @click="reset">
            重置
          </a-button>
        </a-space>
      </a-form>
    </template>
    <template #toolbarLeft>
      <a-space>
        <a-button :disabled="selectedRowKeys.length<1" @click="customerShow = true">
          领取客户
        </a-button>
        <a-button :disabled="selectedRowKeys.length<1" @click="distributionShow = true">
          强制分配
        </a-button>
      </a-space>
    </template>
    <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
             :pagination="pagination" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
             :loading="loading" @change="handleTableChange">
    </a-table>
    <a-modal v-model:visible="customerShow" width="40%" title="领取客户" @ok="onCustomerOk">
      <p>确定要领取该客户吗？</p>
    </a-modal>
    <a-modal v-if="distributionShow" v-model:visible="distributionShow" width="40%" title="强制分配" @ok="onDistributionOk">
      <a-select v-model:value="distributionForm.empAfterName" class="select" show-search
                :filter-option="false" :not-found-content="fetching ? '起码输入2个中文进行搜索' : '没有相关数据'"
                @select="onDistributionCustomer"
                @search="handleSearch">
        <a-select-option v-for="item  in employeeArray" :value="item.name" :key="item.id">{{ item.name }}
        </a-select-option>
      </a-select>
    </a-modal>
  </HxTable>

</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'

import HxTable from '@/components/HxTable'
import { message } from 'ant-design-vue'
import { page, userReceive, compulsoryAllocate } from '@/api/BEnd/appletPublic'
import { list } from '@/api/system/employee'
export default {
  components: {
    HxTable
  },
  setup () {
    const state = reactive({
      customerShow: false,
      fetching: true,
      distributionShow: false,
      loading: false,
      listData: [],
      employeeArray: [],
      searchForm: {},
      distributionForm: {},
      selectedRowKeys: [],
      columns: [
        {
          title: '电话号',
          dataIndex: 'mobile',
          // width: '10%',
          align: 'center'
        },
        {
          title: '注册时间',
          dataIndex: 'regTime',
          align: 'center'
          // width: '10%',
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })

    const onSearch = () => {
      state.loading = true
      state.pagination.current = 1
      loadData()
    }

    const loadData = () => {
      state.loading = true
      page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.loading = false
        })
    }

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = {}
      loadData()
    }
    onMounted(() => {
      loadData()
    })
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }
    const onCustomerOk = () => {
      // if (state.selectedRowKeys.length === 1) {
      userReceive({
        userId: state.selectedRowKeys
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.searchReceiveShow = false
          loadData()
        }
      })
      state.customerShow = false
      //   message.info('领取成功')
      // } else {
      //   message.error('一次只能领取一个客户')
      // }
    }
    // 员工搜索
    const handleSearch = value => {
      var reg = new RegExp('[\u4E00-\u9FA5]+')
      if (reg.test(value) && value.length > 1) {
        state.fetching = false
        shake(state, value)
      }
    }
    const shake = (_that, val) => {
      if (_that.timer !== null) {
        clearTimeout(_that.timer)
      }
      _that.timer = setTimeout(() => {
        list({ name: val }).then((res) => {
          if (res.code === 10000) {
            state.employeeArray = res.data
            state.fetching = true
          }
        })
      }, 1000)
    }
    // 选择用户 赋值
    const onDistributionCustomer = (value, option) => {
      state.distributionForm.empAfterId = option.key
      state.distributionForm.empAfterName = value
    }
    // 强制分配
    const onDistributionOk = () => {
      compulsoryAllocate({
        userId: state.selectedRowKeys,
        empId: state.distributionForm.empAfterId ,
        empName: state.distributionForm.empAfterName
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.distributionShow = false
          loadData()
        }
      })
    }
    return {
      ...toRefs(state),
      reset,
      onSearch,
      loadData,
      handleTableChange,
      onSelectChange,
      onCustomerOk,
      handleSearch,
      onDistributionCustomer,
      onDistributionOk
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
